:root {
    --main-bg-color: #151515;
    --main-shadow-color: rgba(25, 27, 31, 0.48);

    --active-color: #d12d34;
    --inactive-color: #0a0806;

    --active-text-color: #151515;

    --background-semi-color: #2b2a29;

    --header-color: #151515b3;
    --header-shadow-color: rgba(25, 27, 31, 0.57);

    --btn-special-background: #23c4eb;
    --btn-special-text:black;

    --marker-background: #151515;
    --marker-text: white;

    --normal-link-color: #1795b3;
    --report-link-color: #992017;
}